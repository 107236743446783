.container {
    width: 80%;
    height: 100%;
    padding-left: 2%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-left: 18%;
    margin-top: 2%;
    background-color: aliceblue;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  
  /* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

.Video-Player {
    height: 60vh;
    width: 70vw;
    flex-direction: row;
    justify-content: flex-end;
    align-content: flex-end;
    margin-top: 5%;
}