.main-header {
    display: flex;
    width: 100%;
    height: 100px;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 5% 0 5%;
    gap: 32px;
    margin-bottom: 0;
    background-color: #173e6e;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.header-image {
    height: 95%;
    display: block;
    margin: 1em 0 1em 0;
    padding-top: 1%;
}