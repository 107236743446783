.video-view {
    text-align: center;
    height: 120%;
    flex-direction: column;
    background-color: #a9ba9d;
  }
  
.video-player {
    width: 90%;
    margin-top: 3%;
    align-self: center;
}

.control-panel {
    align-self: flex-start;
    height: 100%;
    width: 20%;
}

.viewer-body {
    display: flex;
    height: 100vh;
}